var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.editor && _vm.editable)?_c('div',[_c('button',{class:{ 'is-active': _vm.editor.isActive('bold') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBold()
          .run()}}},[_c('i',{staticClass:"ri-bold text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('italic') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleItalic()
          .run()}}},[_c('i',{staticClass:"ri-italic text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('strike') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleStrike()
          .run()}}},[_c('i',{staticClass:"ri-strikethrough text-green-1002 text-2xl"})]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .clearNodes()
          .run()}}},[_c('i',{staticClass:"ri-format-clear text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('paragraph') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setParagraph()
          .run()}}},[_c('i',{staticClass:"ri-paragraph text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 1 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 1 })
          .run()}}},[_c('i',{staticClass:"ri-h-1 text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 2 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 2 })
          .run()}}},[_c('i',{staticClass:"ri-h-2 text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 3 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 3 })
          .run()}}},[_c('i',{staticClass:"ri-h-3 text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 4 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 4 })
          .run()}}},[_c('i',{staticClass:"ri-h-4 text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 5 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 5 })
          .run()}}},[_c('i',{staticClass:"ri-h-5 text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 6 }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 6 })
          .run()}}},[_c('i',{staticClass:"ri-h-6 text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('bulletList') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()}}},[_c('i',{staticClass:"ri-list-unordered text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('orderedList') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleOrderedList()
          .run()}}},[_c('i',{staticClass:"ri-list-ordered text-green-1002 text-2xl"})]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .undo()
          .run()}}},[_c('i',{staticClass:"ri-arrow-go-back-line text-green-1002 text-2xl"})]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .redo()
          .run()}}},[_c('i',{staticClass:"ri-arrow-go-forward-line text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'left' }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setTextAlign('left')
          .run()}}},[_c('i',{staticClass:"ri-align-left text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'center' }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setTextAlign('center')
          .run()}}},[_c('i',{staticClass:"ri-align-center text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'right' }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setTextAlign('right')
          .run()}}},[_c('i',{staticClass:"ri-align-right text-green-1002 text-2xl"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'justify' }) },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setTextAlign('justify')
          .run()}}},[_c('i',{staticClass:"ri-align-justify text-green-1002 text-2xl"})])]):_vm._e(),_c('editor-content',{attrs:{"editor":_vm.editor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }