<template>
  <div>
    <div v-if="editor && editable">
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <i class="ri-bold text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <i class="ri-italic text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleStrike()
            .run()
        "
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        <i class="ri-strikethrough text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .clearNodes()
            .run()
        "
      >
        <i class="ri-format-clear text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .setParagraph()
            .run()
        "
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        <i class="ri-paragraph text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 1 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        <i class="ri-h-1 text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 2 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        <i class="ri-h-2 text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 3 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        <i class="ri-h-3 text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 4 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        <i class="ri-h-4 text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 5 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        <i class="ri-h-5 text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 6 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      >
        <i class="ri-h-6 text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        "
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        <i class="ri-list-unordered text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleOrderedList()
            .run()
        "
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        <i class="ri-list-ordered text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .undo()
            .run()
        "
      >
        <i class="ri-arrow-go-back-line text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .redo()
            .run()
        "
      >
        <i class="ri-arrow-go-forward-line text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .setTextAlign('left')
            .run()
        "
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
      >
        <i class="ri-align-left text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .setTextAlign('center')
            .run()
        "
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
      >
        <i class="ri-align-center text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .setTextAlign('right')
            .run()
        "
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
      >
        <i class="ri-align-right text-green-1002 text-2xl"></i>
      </button>
      <button
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .setTextAlign('justify')
            .run()
        "
        :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
      >
        <i class="ri-align-justify text-green-1002 text-2xl"></i>
      </button>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';

export default {
  name: 'RichTextEditor',

  components: {
    EditorContent
  },

  props: {
    editable: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    },
    classes: {
      type: String
    }
  },

  data() {
    return {
      editor: null
    };
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.value, false);
    }
  },

  mounted() {
    this.editor = new Editor({
      editable: this.editable,
      content: this.value,
      extensions: [StarterKit, TextAlign],
      editorProps: {
        attributes: {
          class: `bg-white border rounded-lg prose max-w-none prose-sm lg:prose-lg xl:prose-2xl mx-auto p-2 break-words focus:outline-none overflow-y-scroll ${this.classes}`
        }
      },
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      }
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style scoped></style>
